<template>
  <div>
    <h1>欢迎使用本系统</h1>
  </div>
</template>
  
  <script>
export default {
  name:'WelcomePage'
};
</script>
  