<template>
<div class="main-container">
  <!-- 头部内容 -->
  <div class="header-content">
    <div class="title-text">
      <span>编辑订单</span>
    </div>
    <div class="close-btn" @click="handleCloseEditPage">
      <vxe-button type="text" icon="vxe-icon-close"></vxe-button>
    </div>
  </div>
  <!-- 编辑内容 -->
  <div class="body-content">
    <!-- 人员信息 -->
    <div class="user-info-content">
      <div class="flex-row">
        <div class="input-title">
          <span>姓 名：</span>
        </div>
        <div>
          <el-input
            v-model="coalOrderInfo.u_name"
            :clearable="true"
            placeholder="请输入姓名"
          ></el-input>
        </div>
      </div>
      <div class="flex-row">
        <div class="input-title">
          <span>车牌号码：</span>
        </div>
        <div>
          <el-input
            v-model="coalOrderInfo.u_license_plate"
            :clearable="true"
            placeholder="请输入车牌号"
          ></el-input>
        </div>
      </div>
      <div class="flex-row">
        <div class="input-title">
          <span>电话号码：</span>
        </div>
        <div>
          <el-input
            v-model="coalOrderInfo.tel_num"
            :clearable="true"
            placeholder="请输入电话号"
          ></el-input>
        </div>
      </div>
    </div>
    <!-- 运煤信息 -->
    <div class="coal-info-content">
      <div class="flex-row">
        <div class="input-title">
          <span>煤 种：</span>
        </div>
        <div>
          <el-input
            v-model="coalOrderInfo.coal_var"
            :clearable="true"
            placeholder="请输入煤种"
          ></el-input>
        </div>
      </div>
      <div class="flex-row">
        <div class="input-title">
          <span>提煤单号：</span>
        </div>
        <div>
          <el-input
            v-model="coalOrderInfo.coal_bill_number"
            :clearable="true"
            placeholder="请输入提煤单号"
          ></el-input>
        </div>
      </div>
      <div class="flex-row">
        <div class="input-title">
          <span>运费(元)：</span>
        </div>
        <div>
          <el-input
            v-model="coalOrderInfo.cost"
            :clearable="true"
            placeholder="请输入运费"
          ></el-input>
        </div>
      </div>
      <div class="flex-row">
        <div class="input-title">
          <span>目的地：</span>
        </div>
        <div>
          <el-input
            v-model="coalOrderInfo.destination"
            :clearable="true"
            placeholder="请输入目的地"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-btn-content">
    <div class="cancel-btn handle-btn" @click="handleCloseEditPage">
      <span>取消</span>
    </div>
    <div class="submit-btn handle-btn" @click="handleSubmit">
      <span>提交</span>
    </div>
  </div>
</div>
</template>

<script>
// import axios from "axios";
import {mapActions} from "vuex";

export default {
  name: 'editCoalInfo',
  data() {
    return {
      coalOrderInfo: {
        coal_bill_number: '',
      }
    }
  },
  props: {
    coalOrderInfoTemplate: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions({
      updateOrderInfo: 'updateOrderInfo'
    }),
    handleCloseEditPage() {
      this.$emit('handle-close-edit-page')
    },
    handleSubmit() {
      let params = {
        userName: this.coalOrderInfo.u_name,
        licensePlate: this.coalOrderInfo.u_license_plate,
        telNum: this.coalOrderInfo.tel_num,
        destination: this.coalOrderInfo.destination,
        cost: this.coalOrderInfo.cost,
        coalVar: this.coalOrderInfo.coal_var,
        coalBillNumber: this.coalOrderInfo.coal_bill_number || '',
        coalOrderId: this.coalOrderInfo.coal_order_id,
      }
      this.updateOrderInfo(params).then(res => {
        if(res.paCode === '000') {
          this.$message({
            message: res.paMsg || '修改成功',
            type: 'success'
          })
          this.handleCloseEditPage()
          this.handleRefreshData()
        } else {
          this.$message({
            message: res.paMsg || '修改失败',
            type: 'warning'
          })
        }
      })
    },
    handleRefreshData() {
      this.$emit('handle-refresh-data')
    },
  },
  watch: {
    coalOrderInfoTemplate: {
      handler(newValue) {
        this.coalOrderInfo = newValue
      },
      immediate: true,
    }
  }
}
</script>

<style scoped>
.main-container {
  width: 64%;
  height: 48%;
  position: relative;
  border: 1px solid #000;
  background-color: #fefefe;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  overflow: auto;
}
.header-content {
  width: 100%;
  height: 10%;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-text {
  margin-left: 15px;
  font-size: 17px;
  font-weight: bold;
}
.close-btn {
  margin-right: 12px;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.input-title{
  width: 84px;
  margin-right: 4px;
  text-align: right;
  font-size: 16px;
}
.body-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.user-info-content {
  margin: 15px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.coal-info-content {
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.bottom-btn-content {
  position: absolute;
  bottom: 12px;
  right: 15px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.handle-btn {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
.cancel-btn {
  border: 1px solid;
}
.submit-btn {
  border: 1px solid #007bde;
  color: #007bde;
}
/deep/ .el-input__inner {
  border-width: 0;
  border-radius: 0;
  border-bottom: 1px solid #DCDFE6;
  font-size: 15px;
}
</style>