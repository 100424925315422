import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions'

import orderManage from "./modules/orderManage/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    destination: null
  },
  mutations: {
    setDestination(state, destination) {
      state.destination = destination;
    }
  },
  actions: actions.actions,
  getters: {
    destination: (state) => state.destination
  },
  modules: {
    orderManage,
  }
});
