import Vue from "vue";
import VXETable from 'vxe-table'
import {cloneDeep} from 'lodash';
import 'vxe-table/lib/style.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from "./App.vue";
import router from "./router";
import store from './store/index.js';
import baseData from "./baseData.js";

Vue.use(VXETable)
Vue.use(ElementUI)

Vue.config.productionTip = false;

Vue.prototype.$base_url = baseData.baseUrl;
Vue.prototype.cloneDeep = cloneDeep

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
