var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(!_vm.loggedIn)?_c('div',[_c('login',{on:{"login-success":_vm.setUser}})],1):_c('div',{staticClass:"adm"},[_c('div',{attrs:{"id":"nav"}},[(_vm.loggedIn && _vm.user.adm_id)?_c('div',{staticClass:"user-info"},[_vm._v(" 您好，"+_vm._s(_vm.user.adm_id)+" ")]):_vm._e(),(
          (_vm.user && _vm.user.adm_id == 'ztrk01') ||
          _vm.user.adm_id == 'ztrk02' ||
          _vm.user.adm_id == 'SeniorAdmin'
        )?_c('router-link',{attrs:{"to":"/order-management"}},[_vm._v("用户订单管理 ")]):_vm._e(),(
          (_vm.user && _vm.user.adm_id == 'ztrk01') ||
          _vm.user.adm_id == 'ztrk02' ||
          _vm.user.adm_id == 'SeniorAdmin' ||
          _vm.user.adm_id == 'Ceshi'
        )?_c('router-link',{attrs:{"to":"/unusual-orders"}},[_vm._v("异常订单管理 ")]):_vm._e(),(_vm.user && _vm.user.adm_id === 'Guangtian')?_c('router-link',{attrs:{"to":{ name: 'ClienteleManagement', params: { destination: '广田' } }}},[_vm._v("广田管理 ")]):_vm._e(),(_vm.user && _vm.user.adm_id === 'Hongyun')?_c('router-link',{attrs:{"to":{ name: 'ClienteleManagement', params: { destination: '鸿运' } }}},[_vm._v("鸿运管理 ")]):_vm._e(),(_vm.user && _vm.user.adm_id === 'Shunchang')?_c('router-link',{attrs:{"to":{ name: 'ClienteleManagement', params: { destination: '顺昌' } }}},[_vm._v("顺昌管理 ")]):_vm._e(),(_vm.user && _vm.user.adm_id === 'Xinhuatai')?_c('router-link',{attrs:{"to":{
          name: 'ClienteleManagement',
          params: { destination: '新华太' },
        }}},[_vm._v("新华太管理 ")]):_vm._e(),(_vm.user && _vm.user.adm_id === 'Fuding')?_c('router-link',{attrs:{"to":{
          name: 'ClienteleManagement',
          params: { destination: '富鼎' },
        }}},[_vm._v("富鼎管理 ")]):_vm._e(),(_vm.user && _vm.user.adm_id === 'Ceshi')?_c('router-link',{attrs:{"to":{
          name: 'ClienteleManagement',
          params: { destination: '测试' },
        }}},[_vm._v("测试管理账号 ")]):_vm._e(),(
          (_vm.user && _vm.user.adm_id == 'ztrk01') ||
          _vm.user.adm_id == 'ztrk02' ||
          _vm.user.adm_id == 'SeniorAdmin' ||
          _vm.user.adm_id == 'Ceshi'
        )?_c('router-link',{attrs:{"to":"/vehicle-dispatch"}},[_vm._v("派单 ")]):_vm._e(),(
          (_vm.user && _vm.user.adm_id == 'ztrk01') ||
          _vm.user.adm_id == 'ztrk02' ||
          _vm.user.adm_id == 'SeniorAdmin' ||
          _vm.user.adm_id == 'Ceshi'
        )?_c('router-link',{attrs:{"to":"/upload-QRCode"}},[_vm._v("派单管理 ")]):_vm._e(),(
          (_vm.user && _vm.user.adm_id == 'ztrk01') ||
          _vm.user.adm_id == 'ztrk02' ||
          _vm.user.adm_id == 'SeniorAdmin' ||
          _vm.user.adm_id == 'Ceshi'
        )?_c('router-link',{attrs:{"to":"/blacklist-management"}},[_vm._v("黑名单管理 ")]):_vm._e(),(_vm.loggedIn)?_c('button',{staticClass:"logout-btn",on:{"click":_vm.logout}},[_vm._v(" 退出登录 ")]):_vm._e()],1),_c('div',{attrs:{"id":"content"}},[_c('router-view')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }